import { Outlet, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { Colors, generateNotificationText } from '@sportsyou/core'
import {
  Avatar,
  Badge,
  Button,
  Chevron,
  Dropdown,
  Icon,
  EmptyState,
  Link,
  Navbar,
} from '@sportsyou/react-dom-ui'
import {
  Notification as NotificationProps,
  Plan,
  QueryNotificationsRequest,
  QueryRequestsRequest,
  mutationAllNotificationViewed,
  queryNotifications,
  queryRequests,
} from '@sportsyou/api'
import { useFetchApi, usePrevious } from '@sportsyou/react-hooks'

import { MainLayout } from './Main'
import { BuildTeamProvider } from '../hooks/BuildTeam'
import { RootState } from '../../store/rootReducer'
import {
  markAllAlertsAsRead,
  storeAlerts,
} from '../../store/slices/AlertsSlice'
import { selectAllTeamsAndGroups } from '../../store/slices/TeamsSlice'
import { setShouldNavigateToLogin } from '../../store/slices/UserSlice'
import { storeRequests } from '../../store/slices/RequestsSlice'
import { toggleSidebar } from '../../store/slices/LayoutSidebarSlice'

import { URLS } from '../../constants'

import {
  CHAT_MESSAGE_FILTER,
  useAnalytics,
  useChat,
  useWindowFocus,
} from '../hooks'

import ChatBoxes from '../components/ChatBox/ChatBoxes'
import NotificationItem from '../components/Notification/Notification'
import { environment } from '../../environments/environment'

interface Props {
  fullscreen?: boolean
  stretchContent?: boolean
}

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  fullscreen = false,
  stretchContent = false,
}) => {
  const chatClient = useChat({
    filter: CHAT_MESSAGE_FILTER.LISTEN_FOR_MESSAGES_ONLY,
  })
  const { setUserProperties } = useAnalytics()
  const { boot, show: openIntercom, update: updateIntercom } = useIntercom()
  const dispatch = useDispatch()
  const mQuery = window.matchMedia('(min-width: 600px)')
  const navigate = useNavigate()

  const { alerts } = useSelector((state: RootState) => state.alerts)
  const { requests } = useSelector((state: RootState) => state.requests)
  const {
    user: currentUser,
    isUserLoggedIn,
    shouldNavigateToLogin,
  } = useSelector((s: RootState) => s.user)
  const allTeamsAndGroups = useSelector(selectAllTeamsAndGroups)

  const [matchesBreakpoint, setMatchesBreakpoint] = useState(mQuery.matches)
  const [allTeamFeatures, setAllTeamFeatures] = useState<string[]>([])
  const [hasVideoPlan, setHasVideoPlan] = useState(false)

  const { fetch: getAlerts } = useFetchApi(queryNotifications)
  const { fetch: getRequests } = useFetchApi(queryRequests)
  const { fetch: markAllAlertsRead } = useFetchApi(
    mutationAllNotificationViewed
  )

  const prevIsUserLoggedIn = usePrevious(isUserLoggedIn, undefined)

  const pageInitialized = useRef(false)

  const onWindowFocus = useCallback(() => {
    updateIntercom()
  }, [updateIntercom])

  useWindowFocus({ onWindowFocus })

  useEffect(() => {
    setUserProperties()
    return () => {
      // Turn off things when the page unloads
      chatClient.stop(true)
    }
  }, [])

  useEffect(() => {
    mQuery.addEventListener('change', (e) => setMatchesBreakpoint(e.matches))

    return () => {
      mQuery.removeEventListener('change', (e) =>
        setMatchesBreakpoint(e.matches)
      )
    }
  }, [mQuery])

  useEffect(() => {
    const allPlans: Plan[] = allTeamsAndGroups
      .filter((team) => !!team.plans?.length)
      .map((team) => team.plans as Plan[])
      .flat()
    const allFeatures = allPlans
      .map((plan) => plan.featureIds as string[])
      .flat()
    const uniqueFeatures = Array.from(new Set(allFeatures)).sort()

    setAllTeamFeatures(uniqueFeatures)
  }, [allTeamsAndGroups])

  useEffect(() => {
    setHasVideoPlan(
      !!environment.urls.syVideo && allTeamFeatures.includes('VIDEO_TEAM')
    )
  }, [allTeamFeatures])

  useEffect(() => {
    if (isUserLoggedIn) {
      const redirect = localStorage.getItem('redirect')
      if (redirect) {
        localStorage.removeItem('redirect')
        navigate(redirect)
      }
    }
  }, [isUserLoggedIn, navigate])

  useEffect(() => {
    if (shouldNavigateToLogin) {
      dispatch(setShouldNavigateToLogin(false))
      if (window.location.pathname !== '/login') {
        localStorage.setItem(
          'redirect',
          `${window.location.pathname}${window.location.search}`
        )
        navigate('/login')
      }
    }
  }, [dispatch, navigate, shouldNavigateToLogin])

  // TODO: Add Paging
  const fetchAlerts = useCallback(async () => {
    const { data } = await getAlerts({
      notificationsOnly: true,
      perPage: 20,
      page: 1,
      startNotificationId: null,
      unreadOnly: false,
      includeFriends: true,
    } as QueryNotificationsRequest)

    if (data) {
      dispatch(storeAlerts(data))
    }
  }, [dispatch, getAlerts])

  // TODO: Add Paging
  const fetchRequests = useCallback(async () => {
    const { data } = await getRequests({} as QueryRequestsRequest)

    if (data) {
      dispatch(storeRequests(data))
    }
  }, [dispatch, getRequests])

  const setupChat = useCallback(
    () => {
      chatClient.start()
      chatClient.updateUnreadCount()
      pageInitialized.current = true
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // chatClient
    ]
  )

  useEffect(() => {
    if (
      isUserLoggedIn !== prevIsUserLoggedIn &&
      isUserLoggedIn &&
      !pageInitialized.current
    ) {
      fetchAlerts()
      fetchRequests()
      setupChat()
      updateIntercom()
      boot({ hideDefaultLauncher: true })
    } else if (isUserLoggedIn !== prevIsUserLoggedIn && !isUserLoggedIn) {
      chatClient.stop()
      updateIntercom()
      boot()
      pageInitialized.current = false
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // chatClient,
    fetchAlerts,
    fetchRequests,
    isUserLoggedIn,
    setupChat,
  ])

  /**
   * Fetch any new alerts whenever the alerts dropdown is opened
   */
  const onOpenAlerts = useCallback(() => {
    fetchAlerts()
  }, [fetchAlerts])

  /**
   * Fetch any new requests when the requests dropdown is opened
   */
  const onOpenRequests = useCallback(() => {
    fetchRequests()
  }, [fetchRequests])

  const handleOnClickMarkAllAlertsRead = async () => {
    const { data, error, ok } = await markAllAlertsRead({
      includeInvites: false,
    })

    if (data && ok) {
      fetchAlerts()
      dispatch(markAllAlertsAsRead())
    }
    if (error) {
      console.log({ error })
    }
  }

  /**
   * Handle navigation when clicking an alert
   */
  const handleOnClickAlert = async (alert: NotificationProps) => {
    const {
      commentId,
      complaintId,
      eventId,
      sourceId: userId,
      postId,
      teamId,
    } = alert

    if (postId) {
      navigate(`/post/${postId}`, {
        state: {
          commentId,
          complaintId,
        },
      })
    } else if (eventId) {
      const { eventStart } = alert
      if (eventStart) {
        const isoDate = moment(eventStart).format('YYYY-MM-DD')
        navigate(`/calendar/month/${isoDate}/${eventId}`)
      } else {
        navigate(`/calendar/${eventId}`)
      }
    } else if (teamId) {
      navigate(`/teams/${teamId}`)
    } else if (userId) {
      navigate(`/profile/${userId}`, {
        state: { id: userId },
      })
    }
  }

  /**
   * Handle navigation when clicking a request
   */
  const handleOnClickRequest = (request: NotificationProps) => {
    const { sourceId: id, joinedAt, teamId } = request
    if (teamId && !!joinedAt) {
      navigate(`/teams/${teamId}`)
    } else {
      navigate(`/profile/${id}`, {
        state: { id },
      })
    }
  }

  const handleOnClickNavigationLink = (to: string) => {
    if (to.startsWith('/')) {
      navigate(to)
    } else {
      window.open(to, '_blank')
    }
  }

  const handleOnClickBurger = () => {
    dispatch(toggleSidebar())
  }

  const unreadAlerts = alerts?.filter((alert) => !alert.viewedAt)?.length || 0
  const unreadRequests =
    requests?.filter((r: NotificationProps) => {
      return !r.viewedAt ?? !r.friendRequestStatus
    })?.length || 0

  return (
    <RootContainer>
      <BuildTeamProvider>
        <Navbar contentWidth={fullscreen ? '100%' : undefined} sticky>
          <Navbar.Left
            brand
            onClickBrand={(event) => {
              event.preventDefault()
              if (window.location.pathname !== '/home') {
                navigate('/home')
              }
            }}
            product={
              hasVideoPlan
                ? {
                    name: 'sportsYou Video',
                    onClick: () => {
                      window.open(
                        `${environment.urls.syVideo}/videos/?demo=true`,
                        '_blank'
                      )
                    },
                    title: 'sportsYou Video Link',
                  }
                : undefined
            }
            onClickHamburger={handleOnClickBurger}
            showHamburger
          />
          <Navbar.Right>
            {/* Requests */}
            <Dropdown
              maxHeight={360}
              menuWidth='420px'
              onOpen={onOpenRequests}
              placement={'bottomEnd'}
            >
              <Dropdown.Toggle>
                <Button
                  appearance='minimal'
                  collapse
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                  variant='alternate'
                >
                  <IconContainer>
                    <Icon name='UserCircle' />
                    {unreadRequests ? (
                      <StyledBadge
                        count={unreadRequests}
                        dot
                        dotSize={3}
                        offset={{ y: 6 }}
                        size={12}
                      />
                    ) : null}
                  </IconContainer>
                  {matchesBreakpoint && (
                    <DropdownToggleTitle>Requests</DropdownToggleTitle>
                  )}
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Header style={{ fontSize: 14 }}>
                <b>Pending Requests</b>
              </Dropdown.Header>
              {!requests?.length && (
                <Dropdown.Item>
                  <EmptyState
                    description='Contact requests and team/group invites are found here.'
                    header='See All Your Incoming Requests'
                    icon='UserSolid'
                    iconSize={28}
                  />
                </Dropdown.Item>
              )}
              {/* TODO: Should this be react-virtuoso list for paging? */}
              {requests.map((request, index) => {
                return (
                  <Dropdown.Item
                    key={request.id}
                    style={{
                      padding: 0,
                      borderTop:
                        index > 0 ? `1px solid ${Colors.ALTO}` : undefined,
                    }}
                  >
                    <StyledNotificationItem
                      notification={request}
                      message={generateNotificationText(request)}
                      onClick={() => handleOnClickRequest(request)}
                    />
                  </Dropdown.Item>
                )
              })}
            </Dropdown>

            {/* Alerts */}
            <Dropdown
              maxHeight={360}
              menuWidth='440px'
              onOpen={onOpenAlerts}
              placement={'bottomEnd'}
            >
              <Dropdown.Toggle>
                <Button
                  appearance='minimal'
                  collapse
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                  variant='alternate'
                >
                  <IconContainer>
                    <Icon name='Bell' />
                    {unreadAlerts ? (
                      <StyledBadge
                        count={unreadAlerts}
                        dot
                        dotSize={3}
                        offset={{ y: 6 }}
                        size={12}
                      />
                    ) : null}
                  </IconContainer>
                  {matchesBreakpoint && (
                    <DropdownToggleTitle>Alerts</DropdownToggleTitle>
                  )}
                </Button>
              </Dropdown.Toggle>
              <Dropdown.Header
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 14,
                  justifyContent: 'space-between',
                }}
              >
                <b>Alerts &amp; Notifications</b>

                {!!alerts?.length && (
                  <>
                    <DropdownHeaderLink
                      aria-label='Mark All Alerts as Read'
                      color={Colors.PUNCH}
                      title='Mark All Alerts as Read'
                      onClick={handleOnClickMarkAllAlertsRead}
                    >
                      Mark All Read
                    </DropdownHeaderLink>
                    <DropdownHeaderLink
                      aria-label='View Alert Settings'
                      color={Colors.PUNCH}
                      title='View Alert Settings'
                      onClick={() =>
                        handleOnClickNavigationLink('/settings/notifications')
                      }
                    >
                      Settings
                    </DropdownHeaderLink>
                  </>
                )}
              </Dropdown.Header>

              {!alerts?.length && (
                <Dropdown.Item>
                  <EmptyState
                    description='Click **Settings** to pick your settings for emails and alerts.'
                    header='Choose Notification Settings'
                    icon='BellSolid'
                    iconSize={28}
                    useMarkdown
                  />
                </Dropdown.Item>
              )}
              {/* TODO: Should this be react-virtuoso list for paging? */}
              {alerts?.map((alert, index) => {
                return (
                  <Dropdown.Item
                    key={`${alert.id}_${alert.viewedAt ?? ''}`}
                    style={{
                      padding: 0,
                      borderTop:
                        index > 0 ? `1px solid ${Colors.ALTO}` : undefined,
                    }}
                  >
                    <StyledNotificationItem
                      onClick={() => handleOnClickAlert(alert)}
                      notification={alert}
                    />
                  </Dropdown.Item>
                )
              })}
            </Dropdown>

            <Dropdown placement='bottomEnd'>
              <Dropdown.Toggle>
                <Button
                  appearance='minimal'
                  collapse
                  variant='alternate'
                  textStyle={{ alignItems: 'center', display: 'flex' }}
                  // style={{ maxWidth: 120 }}
                >
                  <Avatar
                    id='navbar-avatar'
                    diameter={20}
                    name={currentUser.fullName ?? undefined}
                    uri={currentUser.profileImage?.[0]?.viewUrl ?? undefined}
                  />
                  {matchesBreakpoint && (
                    <DropdownToggleTitle id='navbar-username'>
                      {currentUser.fullName ?? 'Menu'}
                    </DropdownToggleTitle>
                  )}
                  <Chevron
                    fill={Colors.MINE_SHAFT}
                    size={18}
                    style={{ marginLeft: 8 }}
                  />
                </Button>
              </Dropdown.Toggle>

              <Dropdown.Item
                onClick={() => handleOnClickNavigationLink('/profile')}
              >
                Your Profile
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  handleOnClickNavigationLink('/settings/notifications')
                }
              >
                Notification Settings
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleOnClickNavigationLink('/settings')}
              >
                Account Settings
              </Dropdown.Item>
              <Dropdown.Item onClick={openIntercom}>Support</Dropdown.Item>
              <Dropdown.Item
                href={URLS.HELP_DOCS}
                style={{ cursor: 'pointer' }}
                target='_blank'
              >
                Help Documents
              </Dropdown.Item>
              <Dropdown.Divider gap={0} />
              <Dropdown.Item
                onClick={() => handleOnClickNavigationLink('/logout')}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown>
          </Navbar.Right>
        </Navbar>

        <MainLayout fullscreen={fullscreen} stretchContent={stretchContent}>
          {/* <button onClick={banner}>banner</button>
          <button onClick={toast}>toast</button> */}
          <Outlet />
          <ChatBoxes />
        </MainLayout>
      </BuildTeamProvider>
    </RootContainer>
  )
}

const StyledNotificationItem = styled(NotificationItem)`
  flex: 1 1 auto;
`
const DropdownToggleTitle = styled.span`
  margin-left: 10px;
`
const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`
const StyledBadge = styled(Badge)<{ dot?: boolean }>`
  && {
    bottom: calc(100% - 2px);
    box-shadow: 0 0 0 2px ${Colors.WHITE};
    left: calc(100% - 6px);
    padding: ${({ dot }) => (dot ? 0 : '3px 4px')};
    position: absolute;
  }
`
const DropdownHeaderLink = styled(Link)`
  && {
    font-size: inherit;
  }
  &:first-of-type {
    margin-left: auto;
  }
  & + & {
    margin-left: 8px;
  }
`

export default PageLayout
